
@charset "UTF-8";
/*----------------------------------------------------

1. Global Area
2. Header Section
=====================================================================*/
/*=========
Font load
===========*/
@import "~@angular/material/prebuilt-themes/indigo-pink.css";



/*=========
Color Code
===========*/
/*global area*/
/*----------------------------------------------------*/

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  line-height: 1.2em;
  color: #00295c;
  background-color: #ebebeb;
  font-family: "Poppins", system-ui;
  font-weight: 400;
  font-style: normal;
}

/*=========
General Code
===========*/

.custom-chip {
  display: inline-flex;          /* Hace que el chip sea inline y flexible */
  align-items: center;           /* Centra el contenido verticalmente */
  border-radius: 16px;           /* Bordes redondeados para un efecto de chip */
  padding: 0 12px;               /* Espaciado interno (padding) a los lados */
  height: 32px;                  /* Altura del chip */
  font-size: 14px;               /* Tamaño de la fuente */
  font-weight: 500;              /* Grosor de la fuente */
  cursor: default;               /* Cambia el cursor a default */
  user-select: none;             /* Previene la selección de texto */
  white-space: nowrap;           /* Evita que el texto se divida en varias líneas */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); /* Sombra opcional para un efecto más levantado */
  cursor: pointer;
}

.green-chip {
  background-color: rgb(155, 233, 155);
  color: rgb(0, 0, 0);
}

.red-chip {
  background-color: rgb(182, 85, 85);
  color: rgb(255, 255, 255);
}

.custom-scroll-div {
  height: 200px;            /* Altura fija de 200px */
  overflow-y: auto;         /* Scroll vertical */
  border: 1px solid #ddd;   /* Borde opcional para visibilidad */
  padding: 10px;            /* Padding interno opcional */
  background-color: #f8f9fa; /* Color de fondo opcional */
}

::selection {
  color: #fff;
  background-color: #0080ff;
}

::-moz-selection {
  color: #fff;
  background-color: #0080ff;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s all ease-in-out;
}
a:hover, a:focus {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #124191!important;
}

.btn-primary {
background: #0080ff;
border-color: #fff;
color: #fff!important;
border-radius:2em;
padding: .8em 2em;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:active:focus
{
background: #00295c;
border-color: #00295c;
color: #fff!important;
}

.btn-secondary {
  background: #0080ff;
  border-color: #fff;
  color: #fff!important;
  border-radius:2em;
  padding: .5em 2em;
  margin: 0 auto;
  }
  
  .btn-secondary:hover,
  .btn-secondary:active,
  .btn-secondary:focus,
  .btn-secondary:active:focus
  {
    font-weight: 500;
  background: #00ffb4;
  border-color: #00ffb4;
  color: #00295c!important;
  }

.form-control:focus,
button:visited,
button.active,
button:hover,
button:focus,
input:visited,
input.active,
input:hover,
input:focus,
textarea:hover,
textarea:focus,
a:hover,
a:focus,
a:visited,
a.active,
select,
select:hover,
select:focus,
select:visited {
  outline: none;
  box-shadow: none;
  text-decoration: none;
  color: inherit;
}

.form-control {
  box-shadow: none;
}
p, label {
  margin: 0;
}

h1,h2 {
  font-family: "Poppins", system-ui;
  font-weight: 900;
  font-style: normal;
}

h3,
h4,
h5,
h6 {
  font-family: "Poppins", system-ui;
  font-weight: 700;
  font-style: normal;
}

.container-login-general {
  background-color: #00295c;
  color: #FFF;
  width: 100%!important;
  min-height: 100vh!important;
  overflow: hidden;
}

.img-login-top {
  position: absolute;
  top:0;
  right: 0;
  width: 30%;
}

.img-login-bottom {
  position: absolute;
  bottom:0;
  left: 0;
  width: 30%;
  transform: rotate(-180deg);
}

.container-login-general-info-content {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  min-height: 100vh;
}

.logo-login {
  width: 80%;
  max-width: 300px;
  text-align: center;
  margin: 4em;
}

.logo-login h2 {
  color: #fff;
  font-size: 1.2em;
  margin-top: 1em;
}

.container-login {
  color:#00295c;
  background-color: #fff;
  padding: 3em 5em 2em 5em;
  border-radius: 10px;
  box-shadow: 0px 1px 29px 0px rgba(0, 17, 53, 0.5);
}

.container-login h1 {
  color: #00295c;
}

.container {
  z-index: 2;
}

.container-curso {
  margin-bottom: 20px;
}


header {
  position: relative;
  width: 100%;
  height: 120px;
  z-index: 1;
  text-align: center;
  padding-top: 20px;
  box-shadow: 0 3px 17px 1px rgb(70 70 70 / 10%);
  background-color: #00295c;
  color: #fff;
}

header:after {
  background-image: url("/assets/img/X-CornerPattern-03.png");
  background-position:right;
  background-repeat: no-repeat;
  background-size: 10%;
  content: "";
  height: 100%;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}


header .container {
  position: relative;
  z-index: 2;
}

header h2 {
  font-size: 1.2em;
  display: inline-block;
  color: #00ffb4!important;
  margin-bottom: 0px; 
}

header .logo-header {
  max-width: 250px;
  margin-top:10px;
}

header .btn-header {
  padding: .5em 2em;
  text-decoration: none;
  margin-left: 10px;
  font-size: .8em;
  border: 1px solid #fff!important;
}

.container-footer {
  background-color: #00295c;
  width: 100%;
  margin-top: 1em;
}

footer {
  position: relative;
  z-index: 3;
  background: none;
  color: #fff;
  width: 100%;
  padding: 1em;
}

/*footer:after {
  background-image: url("/assets/img/X-CornerPattern-01.png");
  background-position:left;
  background-repeat: no-repeat;
  background-size: 10%;
  content: "";
  height: 100%;
  left: 0%;
  opacity: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
}*/

.container-content-footer {
  border-top: 1px solid #D0D0CE;
  padding-top: 1.5em;
}

.logo-footer {
  max-width: 200px;
}


.container-info-home {
  min-height: 78vh!important;
}

.container-modulo-home {
  width: auto;
  padding: 2em;
  background: #fff;
}
.img-modulo-home {
  position: relative;
  z-index: 2;
  display: inline-block;
}

.mat-tab-group {
  background: #fff;
  border-radius: 20px;
  padding: 2em;
}

.mat-tab-body-content {
  min-height: 100vh;
}




/*---------------------------------------------------- */
/*Responsive area*/
/*----------------------------------------------------*/

@media screen and (min-width: 1300.1px) {
  .pos-flex {
    display: flex;
  }
}

@media screen and (max-width: 1800px) {
  
}
@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1440px) {
  
}
@media screen and (max-width: 1399px) {
  
}
@media screen and (max-width: 1300px) {
  
}
@media screen and (max-width: 1070px) {

}
@media screen and (max-width: 991px) {

  
  .container-login-general-info{
    position: relative;
    display: inline-block;
    width: 100%!important;
    float: initial;
    min-height: 100vh!important;
  }

  .logo-login {
    margin-top: 2em;
  }

  header {
    position: relative;
    width: 100%;
    height: 170px;
    z-index: 9;
    text-align: center;
    padding-top: 10px;
    margin-bottom: 20px;
    color: #124191;
    background-color: #fff;
    box-shadow: 0 3px 17px 1px rgb(70 70 70 / 10%);
  }

  header img {
    width: 50%;
  }

  footer {
    margin-top: 2em;
  }
}
@media screen and (max-width: 580px) {
}
@media screen and (max-width: 480px) {

}
@media screen and (max-width: 380px) {

}
/*---------------------------------------------------- */
/*---------------------------------------------------- */
